<template>
    <v-app style="background-color: #bbb;">
        <HeaderContainer />

        <v-main style="background-color: #ddd;">
            <v-card class="ma-4">
                <v-card-title>Olá, {{ user.name }}</v-card-title>
                <v-card-text>
                    <v-expansion-panels class="py-5" focusable v-model="panel">
                        <v-expansion-panel>
                            <v-expansion-panel-header>Meus Pedidos</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Nº
                                                </th>
                                                <th class="text-center">
                                                    Data/Hora
                                                </th>
                                                <!-- <th class="text-center">
                                                    Forma Pgto
                                                </th> -->
                                                <th class="text-right">
                                                    R$ Total
                                                </th>
                                                <th class="text-center">
                                                    Status
                                                </th>
                                                <th class="text-center">
                                                    Ações
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(pedido, index) in pedidos"
                                                :key="index"
                                            >
                                                <td class="text-left">{{ pedido.id }}</td>
                                                <td class="text-center">{{ formatDate(pedido.created_at) }}</td>
                                                <!-- <td class="text-center">{{ pedido.forma_pagamento }}</td> -->
                                                <td class="text-right">R${{ formatPrice(pedido.total_liquido) }}</td>
                                                <td class="text-center">{{ pedido.status }}</td>
                                                <td class="text-center">
                                                    <v-btn
                                                        @click.stop="openCheckout(pedido.uuid)"
                                                        color="primary"
                                                        icon
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="white--text"
                        color="primary"
                        text
                        @click="$router.back()"
                    >
                        Voltar
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-main>

        <FooterContainer />
    </v-app>
</template>

<script>
import FooterContainer from '../FooterContainer';
import HeaderContainer from '../HeaderContainer';

export default {
    name: 'HomePage',

    components: {
        FooterContainer,
        HeaderContainer,
    },

    data: () => ({
        panel: [0],
        loading: false,
        pedidos: [],
        user: {},
    }),

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.loading = true;
            this.$http.get('ecommerce/my-orders').then(resp => {
                const data = resp.data;

                if (data.type === 'warning') {
                    this.$toast.error(data.msg);
                    return;
                }

                this.user = data.data.user;
                this.pedidos = data.data.pedidos;
            })
            .catch(e => (this.$toast.error('Não foi possivel concluir a operação. ' + e)))
            .finally(() => (this.loading = false))
        },

        openCheckout(uuid) {
            // const url = (document.URL.indexOf('localhost') !== -1)
            //    ? `http://lojafacil.localhost:8000/ecommerce/checkout/${uuid}`
            //    : `https://cliente.lojafacil.app/ecommerce/checkout/${uuid}`;
            const url = document.location.origin + `/ecommerce/checkout/${uuid}`;
            window.open(url);
        },
    },
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
