<template>
    <v-app>
        <HeaderContainer
            ref="header"
            @login="onLogin"
        />

        <v-main style="background-color: #ddd;">
            <v-container fluid>
                <v-card
                    class="mx-auto my-12"
                    max-width="900"
                >
                    <v-card-title justify="center">
                        Seu Carrinho
                    </v-card-title>

                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="nome"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :hide-default-footer="true"
                            class="elevation-1"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.nome="{ item }">
                                <!-- <v-avatar>
                                    <v-img
                                        :src="getImageUrl(item.imagens)"
                                    />
                                </v-avatar> -->
                                <span class="ml-2">{{ (item.nome) }}</span>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.valor="{ item }">
                                <span>R$&nbsp;{{ formatPrice(getValor(item)) }}</span>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <!-- <template v-slot:item.total="{ item }">
                                <span>R$&nbsp;{{ formatPrice(getValor(item) * item.quantidade) }}</span>
                            </template> -->
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.action="{ item }">
                                <v-icon @click.stop="removeFromCart(item.id)" color="red">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-title justify="center">
                        Total: R$ {{ formatPrice(getTotalLiquido(items)) }}
                    </v-card-title>

                    <v-card-actions>
                        <v-col class="text-right">
                        <v-btn
                            v-if="items.length"
                            class="white--text"
                            color="primary"
                            large
                            :loading="loading"
                            @click="continuar"
                        >
                            Finalizar Pedido
                        </v-btn>
                        </v-col>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn
                            class="white--text"
                            color="primary"
                            text
                            @click="$router.push('/')"
                        >
                            Continuar Comprando
                        </v-btn>
                    </v-card-actions>

                </v-card>

            </v-container>
        </v-main>

        <FooterContainer />
    </v-app>
</template>

<script>
import FooterContainer from './FooterContainer';
import HeaderContainer from './HeaderContainer';
import imageMixin from '@/mixins/image';
import { totalLiquido } from '@/utils/calculos';

export default {
    name: 'CartPage',

    mixins: [ imageMixin ],

    components: {
        FooterContainer,
        HeaderContainer,
    },

    data: () => ({
        loading: false,
        headers: [
          {
            text: 'PRODUTO',
            align: 'start',
            sortable: false,
            value: 'nome',
          },
          {
            text: 'QTD',
            align: 'end',
            sortable: false,
            value: 'quantidade',
          },
          {
            text: 'R$ UN',
            align: 'end',
            sortable: false,
            value: 'valor',
          },
        //   {
        //     text: 'TOTAL',
        //     align: 'end',
        //     sortable: false,
        //     value: 'total',
        //   },
          {
            text: 'REMOVER',
            align: 'end',
            sortable: false,
            value: 'action',
          },
        ],
        items: []
    }),

    mounted() {
        this.getCartItems();
    },

    methods: {
        getCartItems() {
            const cart = this.$store.state.cart;
            const ids = {
                pacotes: cart.filter(c => c.tipo === 'pacotes').map(c => c.id),
                produtos: cart.filter(c => c.tipo === 'produtos').map(c => c.id),
            };

            this.loading = true;
            this.$http.post('ecommerce/get-cart-items', { ids }).then(resp => {
                const { data } = resp;

                data.produtos.map(e => {
                    const item = cart.filter(c => c.tipo === 'produtos').find(c => c.id === e.id);
                    e.quantidade = item.quantidade;
                });

                data.pacotes.map(e => {
                    const item = cart.filter(c => c.tipo === 'pacotes').find(c => c.id === e.id);
                    e.quantidade = item.quantidade;
                });

                this.items = [...data.produtos, ...data.pacotes];
            }).finally(() => (this.loading = false));
        },

        getValor(item) {
            return item.valor_promocional || item.valor_venda;
        },

        getTotalLiquido(produtos) {
            return totalLiquido(produtos);
        },

        async removeFromCart(id) {
            if (!confirm('Deseja realmente REMOVER?')) {
                return;
            }

            this.$store.commit('removeFromCart', id);

            const index = this.items.findIndex(e => e.id === id);
            if (index > -1) {
                this.items.splice(index, 1);
            }

            if (!this.items.length) {
                this.$router.push('/');
            }
        },

        async continuar() {
            let isAuthenticated = false;
            try {
                isAuthenticated = this.$store.state.user?.id;
            } catch (error) {
                isAuthenticated = false;
            }

            if (!isAuthenticated) {
                this.$refs.header.openLoginDialog();
                return;
            }

            const cart = JSON.parse(JSON.stringify(this.$store.state.cart));

            const items = {
                pacotes: cart.filter(c => c.tipo === 'pacotes'),
                produtos: cart.filter(c => c.tipo === 'produtos'),
            };

            this.loading = true;
            this.$http.post('pedidos', items).then(resp => {
                if (resp.data.type === 'warning') {
                    this.$toast.error(resp.data.msg);
                    return;
                }

                const data = resp.data.data;

                this.$router.push(`/ecommerce/checkout/${data.uuid}`);
                this.$store.commit('setCart', []);
            }).finally(() => (this.loading = false));
        },

        onLogin() {
            this.continuar();
        },
    },
}
</script>
