export default {
    methods: {
        getImageUrl(imagem) {
            if (!imagem?.url) {
                return '';
            }

            const url = imagem.tmp
                ? `${this.$urlAPI}/files/tmp/${imagem.url}`
                : `${this.$urlAPI}/storage/${imagem.url}`;

            return url;
        },
    }
}