<template>
    <v-app style="background-color: #bbb;">
        <HeaderContainer />

        <v-main style="background-color: #ddd;">
            <v-card class="ma-4">
                <v-card-title>Olá, {{ user.name }}</v-card-title>
                <v-card-text>
                    <v-expansion-panels class="py-5" focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Minha Conta</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <FormInput
                                    label="Nome"
                                    v-model="user.name"
                                />
                                <FormInputMask
                                    label="CPF"
                                    placeholder="000.000.000-00"
                                    :mask="['###.###.###-##']"
                                    v-model="user.cpf"
                                />
                                <FormInput
                                    label="E-mail"
                                    v-model="user.email"
                                />
                                <!-- <FormInputMask
                                    label="Telefone"
                                    placeholder="+55 (99) 99999-9999"
                                    :mask="['+## (##) ####-####', '+## (##) #####-####']"
                                    v-model="user.phone"
                                /> -->

                                <VuePhoneNumberInput
                                    v-model="user.phone"
                                    @update="phone = $event"
                                    :translations="{
                                        countrySelectorLabel: 'Código do país',
                                        countrySelectorError: 'Código do país',
                                        phoneNumberLabel: 'Telefone',
                                        example: ''
                                    }"
                                    no-example
                                    class="mt-2"
                                />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="white--text"
                        color="primary"
                        text
                        @click="$router.back()"
                    >
                        Voltar
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        class="white--text"
                        color="primary"
                        @click="salvar"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-main>

        <FooterContainer />
    </v-app>
</template>

<script>
import FooterContainer from '../FooterContainer';
import HeaderContainer from '../HeaderContainer';
import FormInput from '@/components/FormInput';
import FormInputMask from '@/components/FormInputMask';
import { validateCpf } from '@/utils/validateCpf';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: 'HomePage',

    components: {
        FooterContainer,
        HeaderContainer,
        FormInput,
        FormInputMask,
        VuePhoneNumberInput,
    },

    data: () => ({
        loading: false,
        user: [],
        phone: '',
    }),

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.loading = true;
            this.$http.get('ecommerce/my-account').then(resp => {
                const data = resp.data;

                if (data.type === 'warning') {
                    this.$toast.error(data.msg);
                    return;
                }

                this.user = data.data;
            })
            .catch(e => (this.$toast.error('Não foi possivel concluir a operação. ' + e)))
            .finally(() => (this.loading = false))
        },

        salvar() {
            const invalidCpf = !validateCpf(this.user.cpf);

            if (this.user.cpf && invalidCpf) {
                this.$toast.error('Informe um CPF válido');
                return;
            }

            this.user.phone = this.phone.formatInternational;

            this.loading = true;
            this.$http.put('ecommerce/user', this.user).then(resp => {
                const data = resp.data;

                console.log(data)

                if (data.type === 'warning') {
                    this.$toast.error(data.msg);
                    return;
                }

                this.$toast.success('Cadastro atualizado');
            })
            .catch(e => (this.$toast.error('Não foi possivel concluir a operação. ' + e)))
            .finally(() => (this.loading = false))
        },


    },
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
