
export const totalBruto = (produtos) => {
    const total = produtos.reduce((a, b) =>
        a + (+b.quantidade || 0) * b.valor_venda, 0
    );
    return total;
};

export const totalLiquido = (produtos) => {
    const total = produtos?.reduce((a, b) =>
        a + (+b.quantidade || 0) * (b.valor_promocional || b.valor_venda), 0
    ) || 0;
    return total;
};
